.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #cdcdcd;
  padding: 24px 0;
  margin-top: 30px;
  border-top: 1px solid #bbb;
  color: #333;
  min-height: 60px;

  .content-wrapper {
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;

    .app {
      font-weight: bold;
      min-width: 148px;
      font-size: 25px;
      line-height: 34px;
    }
  }

  ul {
    &.languages-list {
      padding: 0;
      margin: 0;
      list-style: none;
      list-style-type: none;
      height: min-content;
      font-size: 14px;

      li {
        cursor: pointer;
        width: min-content;
        margin-left: auto;
        border-bottom: 1px solid transparent;
        font-size: 12px;

        &:hover {
          border-bottom: 1px solid;
          font-weight: 600;
        }
      }
    }
  }
}
