.container {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.address-section {
  width: auto;
  min-height: 32px;
  margin: 22px 0;
  padding: 0 19px;
  border-bottom: 1px solid #979797;
  text-align: center;

  color: rgba(151, 151, 151, 0.52);
  font-size: 24px;
  line-height: 33px;
}
