.primary {
  background-color: #FD7F3F;
  color: #fff;
}

.secondary {
  color: #fff;
  background-color: #6c757d;
}

.success {
  background-color: #28a745;
  color: #fff;
}

.danger {
  background-color: #dc3545;
  color: #fff;
}

.warning {
  background-color: #ffc107;
  color: #000;
}

.info {
  background-color: #17a2b8;
  color: #fff;
}