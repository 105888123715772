.content {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.helper-text {
  color: #009efb;
  font-size: 20px;
  line-height: 27px;
  margin-top: 87px;
  margin-bottom: 31px;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-list > * {
  margin: 14px;
}

.buttons-wrapper {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.buttons-wrapper button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  min-height: 48px;
  margin: 0 10px;
}

.check-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
