.container {
  display: block;
}

.address-section {
  width: 100%;
  min-height: 32px;
  margin: 22px 0;
  border-bottom: 1px solid #707070;
  border-top: 1px solid;
  text-align: center;
  font-size: 20px;
  color: #707070;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}