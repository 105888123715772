.navbar-custom {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  z-index: 999;
  background-color: #fff;
}

.navbar-custom .navbar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 1080px;
  list-style: none;
  
  li {
    margin: 0 10px;
  }
}

.link {
  text-decoration: none;
  color: #333;
}
