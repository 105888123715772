.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
}

.container section.estimation-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 300px;
  border: 1px solid #707070;
  color: #2699fb;
  padding: 10px;
  padding-bottom: 35px;
}

.container section.estimation-box .header-text {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.container section.estimation-box .estimated-value {
  font-size: 30px;
  font-weight: bold;
}

.container section.address-section {
  width: 100%;
  max-width: 700px;
  margin: 22px 0;
  border-bottom: 1px solid #707070;
  border-top: 1px solid;
  text-align: center;
  font-size: 20px;
  color: #707070;
}

.container section.quotes {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 700px;
}
