.q-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 33%;
}

.q-box .title {
  white-space: nowrap;
}

.q-box .q-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.q-box .q-content > * {
  box-sizing: border-box;
  margin: 10px;
  flex-shrink: 1;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.14);
  padding: 3px;
  flex-basis: 20%;
}
