.button button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  max-width: 296px;
  min-height: 48px;
  margin: 0 10px;

  border: none;
  border-radius: 0;

  color: #ffffff;
  font-size: 25px;
  line-height: 34px;

  height: 3.5rem;
  width: 15rem;
  background-color: #79C0E3;
  flex: 1;
}

.button button:disabled, .button button:disabled:hover{
  background-color: #E9EAEE;
}
.button button.clear {
  color: #9098AA;
  background-color: transparent;
  border: 2px solid;
}

.button button.clear:hover {
  background-color: rgb(240, 101, 14);
  border-color: #E88548;
  color: white;
}

.button .btn-primary
{
  background-color: #E88548;
  border-radius: 3rem;
}

.button .btn-primary:hover
{
  background-color: rgb(240, 101, 14);
  border-radius: 3rem;
}
