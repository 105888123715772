.input-wrapper {
}

.input-wrapper input {
  all: none;
  display: block;
  width: 100%;
  padding: 8px 10px;
  color: #009efb;
  border: none;
  border-bottom: 1px solid;
  font-size: 18px;
  line-height: 33px;
  outline: none;
}
