.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.container .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  color: #6D6F72;
}

.container .header .main {
  font-size: 4em;
}

.container .header .sub {
  font-size: 1em;
}