.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper .form-group {
  width: 100%;
}

.input-wrapper .suggestions {
  position: absolute;
  top: 43px;
  max-height: 33vh;
  width: 100%;
  overflow: auto;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  background-color: #fff;
}

.input-wrapper .suggestions .suggestion {
  padding: .375rem .75rem;
  color: #2b2b2b;
  cursor: pointer;
}

.input-wrapper .suggestions .suggestion:hover {
  background-color: #dedede;
}

.input-wrapper .suggestions .suggestion:focus {
  background-color: #dedede;
}
