@keyframes appear {
  0% {
    /* transform: translateX(-100%); */
    width: 0;
    height: 0;
    border-radius: 0 0 100vh 0;
  }
  100% {
    /* transform: translateX(0); */
    height: 100%;
    width: 100%;
  }
}

@keyframes color-fade {
  0% {
    background-color: #F89558;
  }
  33% {
    background-color: #F8a568;
  }
  66% {
    background-color: #E88548;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.75;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.circle-appear {
  animation: 512ms linear 0ms 1 fade, 512ms ease-out 0s 1 appear,
    2.5s linear 512ms infinite color-fade;
}

.ls-container {
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(#E88548, 0.9);
  color: #e7e7e7;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}
