.buttons-wrapper {
  margin: 77px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap-reverse;
  max-width: 100vw;
}

.buttons-wrapper button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  max-width: 296px;
  min-height: 48px;
  margin: 0 10px;

  border: none;
  border-radius: 0;

  color: #ffffff;
  font-size: 25px;
  line-height: 34px;

  height: 80px;
  width: 322px;
  background-color: #009efb;

  flex: 1;
}

.buttons-wrapper button.clear {
  color: #009efb;
  background-color: transparent;
  border: 1px solid;
}

.buttons-wrapper button.clear:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: white;
}

.content {
  width: 100%
}

.summary-content {
  display: flex;
  padding: 0px 40px;
}

.holder {
  width: 100%;
  margin: 0px 10px;
}

.title {
  font-size: 1.5em;
  color: #8e8e8e;
  justify-content: center;
  display: flex;
}