.home-container {
  display: flex;
  flex-direction: column;
  margin: 15px;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

