.container {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.comment {
  color: #009efb;
  font-size: 42px;
  font-weight: 600;
  line-height: 57px;
  margin-top: 77px;
}
