.sml {
  width: 2em;
  height: 2em;
  font-size: 1em;
}
.med {
  width: 2em;
  height: 2em;
  font-size: 1.5em;
}
.lrg {
  width: 2em;
  height: 2em;
  font-size: 2em;
}
.huge {
  width: 2em;
  height: 2em;
  font-size: 2.5em;
}

.holder {
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}