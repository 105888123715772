.container {
  display: flex;
  justify-content: space-between;
  background-color: #f6fcff;

  margin-bottom: 3px;
}

.container.container-selected {
  background-color: #dcf1fe;
}

.container .text {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 15px 27px;
  color: #009efb;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}

.container .select-button {
  display: flex;
  flex-flow: column;
  justify-content: center;

  background-color: #009efb4c;
  padding: 15px 44px;

  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;
  cursor: pointer;

  border: none;
  border-radius: unset;
}

.container .select-button.selected {
  background-color: #009efb;
}
