.layout {
  display: flex;
  box-sizing: border-box;
  height: auto;
  min-height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  .layout-content {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0 30px;
    margin: 0 auto;
  }
}
