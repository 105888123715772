.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content .contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 51px;
  min-height: 130px;
}

.terms {
  color: #979797;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 200px;
}

.terms a {
  font-weight: 900;
  text-decoration: underline;
}
