.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-list > * {
  margin: 14px;
}

.buttons-wrapper {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.buttons-wrapper button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  min-height: 48px;
  margin: 0 10px;
  margin-top: 20px;
}

.slider-wrapper {
  display: flex;
  color:#009EFB;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 20px;
}

.content {
  width: 80%;
  color:#009EFB;
  font-size: 1em;
}

.sliderContainer {
  width: 100%;
}

.tag {
  width: 30%;
  font-size: 1.5em;
  margin-bottom: 45px;
}
.follower {
  text-align: end;
  font-size: 1.5em;
}

.title {
  margin-bottom: 50px;
}

.buttons-wrapper {
  margin: 77px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap-reverse;
  max-width: 100vw;
}

.buttons-wrapper button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  max-width: 296px;
  min-height: 48px;
  margin: 0 10px;

  border: none;
  border-radius: 0;

  color: #ffffff;
  font-size: 25px;
  line-height: 34px;

  height: 80px;
  width: 322px;
  background-color: #009efb;

  flex: 1;
}

.buttons-wrapper button.clear {
  color: #009efb;
  background-color: transparent;
  border: 1px solid;
}

.buttons-wrapper button.clear:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: white;
}
