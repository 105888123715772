body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.styles_input-wrapper__3EN8B {
  position: relative;
  width: 100%;
}

.styles_input-wrapper__3EN8B .styles_form-group__DRb-S {
  width: 100%;
}

.styles_input-wrapper__3EN8B .styles_suggestions__UbYLE {
  position: absolute;
  top: 43px;
  max-height: 33vh;
  width: 100%;
  overflow: auto;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  background-color: #fff;
}

.styles_input-wrapper__3EN8B .styles_suggestions__UbYLE .styles_suggestion__3I7IX {
  padding: .375rem .75rem;
  color: #2b2b2b;
  cursor: pointer;
}

.styles_input-wrapper__3EN8B .styles_suggestions__UbYLE .styles_suggestion__3I7IX:hover {
  background-color: #dedede;
}

.styles_input-wrapper__3EN8B .styles_suggestions__UbYLE .styles_suggestion__3I7IX:focus {
  background-color: #dedede;
}

.styles_input-wrapper__25TnO {
}

.styles_input-wrapper__25TnO input {
  all: none;
  display: block;
  width: 100%;
  padding: 8px 10px;
  color: #009efb;
  border: none;
  border-bottom: 1px solid;
  font-size: 18px;
  line-height: 33px;
  outline: none;
}

.styles_container__2YJjX {
  width: 100%;
  height: 0;
  padding-bottom: 15%;
  position: relative;
}
.styles_player__PGz8O {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.sml {
  width: 2em;
  height: 2em;
  font-size: 1em;
}
.med {
  width: 2em;
  height: 2em;
  font-size: 1.5em;
}
.lrg {
  width: 2em;
  height: 2em;
  font-size: 2em;
}
.huge {
  width: 2em;
  height: 2em;
  font-size: 2.5em;
}

.holder {
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.property-card {
  display: flex;
  flex-direction: column;
  width: 212px;
  height: 280px;
  color: #6D6B6D;
  box-shadow: 0 3px 4px 0 rgba(40,47,60,0.05), 0 20px 25px 0 rgba(40,47,60,0.05), 0 0 25px 0 rgba(40,47,60,0.05);
}

.property-holder {
  flex: 4 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  color: #009efb;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}

.property-title {
  color: #5A6169;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}

.property-description {
  color: #8e8e8e;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.property-more {
  color: #5A6169;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;
}

.button-holder {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8f99ac36;
  opacity: 0.5;
  color: #8F99AC;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}
.property-card.selected .button-holder {
  background-color:#3FAFFD;
  color: #fff;
  opacity: 1;
}

.button-holder:hover {
  cursor: pointer;
}

.primary {
  background-color: #FD7F3F;
  color: #fff;
}

.secondary {
  color: #fff;
  background-color: #6c757d;
}

.success {
  background-color: #28a745;
  color: #fff;
}

.danger {
  background-color: #dc3545;
  color: #fff;
}

.warning {
  background-color: #ffc107;
  color: #000;
}

.info {
  background-color: #17a2b8;
  color: #fff;
}
.styles_container__1Gxzo {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 20px;
}

.styles_content-box__3XEmk {
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0 28px;
  padding: 20px;
}

.styles_content-text__3cZUZ {
  color: #FD7F3F;
  font-size: 30px;
  font-weight: bold;
}

.styles_title-text__H0UfQ {
  text-align: center;
  color: #FD7F3F;
  font-size: 32px;
  line-height: 43px;
  /* white-space: pre; */
  margin-right: 6px;
}

.styles_value-text__3Z886 {
  color: #FD7F3F;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
}

@-webkit-keyframes appear {
  0% {
    /* transform: translateX(-100%); */
    width: 0;
    height: 0;
    border-radius: 0 0 100vh 0; }
  100% {
    /* transform: translateX(0); */
    height: 100%;
    width: 100%; } }

@keyframes appear {
  0% {
    /* transform: translateX(-100%); */
    width: 0;
    height: 0;
    border-radius: 0 0 100vh 0; }
  100% {
    /* transform: translateX(0); */
    height: 100%;
    width: 100%; } }

@-webkit-keyframes color-fade {
  0% {
    background-color: #F89558; }
  33% {
    background-color: #F8a568; }
  66% {
    background-color: #E88548; } }

@keyframes color-fade {
  0% {
    background-color: #F89558; }
  33% {
    background-color: #F8a568; }
  66% {
    background-color: #E88548; } }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.75; }
  80% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

@keyframes fade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.75; }
  80% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

.circle-appear {
  -webkit-animation: 512ms linear 0ms 1 fade, 512ms ease-out 0s 1 appear, 2.5s linear 512ms infinite color-fade;
          animation: 512ms linear 0ms 1 fade, 512ms ease-out 0s 1 appear, 2.5s linear 512ms infinite color-fade; }

.ls-container {
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(232, 133, 72, 0.9);
  color: #e7e7e7;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0; }

.nav-map-container {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  left: 0px;
  background-color: #fff;
}

.nav-map-container .bar {
  display: inline-block;
  background-color: #FD7F3F;
  height: 12px;
}
.nav-map-container .barBG {
  display: inline-block;
  background-color: #E9EAEE;
  height: 12px;
}

.nav-map-container .steps-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-top: 2px;
}

.nav-map-container .steps-container > .step {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.4;
  color: #6D6B6D;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;

  white-space: nowrap;
}

.nav-map-container .steps-container > .step.highlight {
  opacity: 1;
}

.nav-map-container .steps-container > .step.hidden {
  opacity: 0;
}

.styles_container__26lme {
  display: flex;
  justify-content: space-between;
  background-color: #f6fcff;

  margin-bottom: 3px;
}

.styles_container__26lme.styles_container-selected__3DgBC {
  background-color: #dcf1fe;
}

.styles_container__26lme .styles_text__2ps_4 {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 15px 27px;
  color: #009efb;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}

.styles_container__26lme .styles_select-button__3Ik7q {
  display: flex;
  flex-flow: column;
  justify-content: center;

  background-color: #009efb4c;
  padding: 15px 44px;

  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;
  cursor: pointer;

  border: none;
  border-radius: unset;
}

.styles_container__26lme .styles_select-button__3Ik7q.styles_selected__2SiMW {
  background-color: #009efb;
}



.buttons-wrapper {
  margin: 77px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap-reverse;
  max-width: 100vw;
}

.button button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  max-width: 296px;
  min-height: 48px;
  margin: 0 10px;

  border: none;
  border-radius: 0;

  color: #ffffff;
  font-size: 25px;
  line-height: 34px;

  height: 3.5rem;
  width: 15rem;
  background-color: #79C0E3;
  flex: 1 1;
}

.button button:disabled, .button button:disabled:hover{
  background-color: #E9EAEE;
}
.button button.clear {
  color: #9098AA;
  background-color: transparent;
  border: 2px solid;
}

.button button.clear:hover {
  background-color: rgb(240, 101, 14);
  border-color: #E88548;
  color: white;
}

.button .btn-primary
{
  background-color: #E88548;
  border-radius: 3rem;
}

.button .btn-primary:hover
{
  background-color: rgb(240, 101, 14);
  border-radius: 3rem;
}

.container {
  box-sizing: border-box;
  width: 100%;
  border: 0; }
  .container .alert {
    border-radius: 0; }

.styles_container__sddMc {
  padding: 5px 15px;
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 3px 4px 0 rgba(40,47,60,0.05), 0 20px 25px 0 rgba(40,47,60,0.05), 0 0 25px 0 rgba(40,47,60,0.05);
}

.styles_item__cnrnI {
  color: #8e8e8e;
  display: flex;
  font-size: 1em;
  align-items: center;
  padding-right: 15px;
}

.styles_item-dot__18d51 {
  color: #FD7F3F;
  font-size: 1.5em;
  padding: 0px 4px;
}

.styles_pagination__wNWPa {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.styles_pagination-item__1bxqS {
  color: #FD7F3F;
  font-size: 1.5em;
  font-weight: 200;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  border-width: 0px;
  padding: 0px;
  background-color: #fff;
}

.styles_page__24aAZ {
  color: #FD7F3F;
  font-size: 0.5em;
  opacity: 0.5;
  margin: 0px 5px;
  border-width: 0px;
  padding: 0px;
  background-color: #f6fcff;
}

.styles_current-page__1DQYP {
  opacity: 1;
  font-size: 0.7em;
}

.styles_footer__ob9-2 {
  widows: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_footer-empty__3FDpZ {
  color: #25afff;
  margin: 1px;
  height: 3px;
}

.styles_pages__3iqDN {
  display: flex;
  justify-content: space-around;
}
.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #cdcdcd;
  padding: 24px 0;
  margin-top: 30px;
  border-top: 1px solid #bbb;
  color: #333;
  min-height: 60px; }
  .footer-container .content-wrapper {
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px; }
    .footer-container .content-wrapper .app {
      font-weight: bold;
      min-width: 148px;
      font-size: 25px;
      line-height: 34px; }
  .footer-container ul.languages-list {
    padding: 0;
    margin: 0;
    list-style: none;
    list-style-type: none;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    font-size: 14px; }
    .footer-container ul.languages-list li {
      cursor: pointer;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      margin-left: auto;
      border-bottom: 1px solid transparent;
      font-size: 12px; }
      .footer-container ul.languages-list li:hover {
        border-bottom: 1px solid;
        font-weight: 600; }

.navbar-custom {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  z-index: 999;
  background-color: #fff; }

.navbar-custom .navbar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 1080px;
  list-style: none; }
  .navbar-custom .navbar-container li {
    margin: 0 10px; }

.link {
  text-decoration: none;
  color: #333; }

.styles_search-wrapper__2q7-3 {
  width: 75%;
}

.styles_search-wrapper__2q7-3 .styles_sub-header__1e7cj {
  width: 100%;
  color: #6D6F72;
  font-size: 2em;
}

.styles_search-wrapper__2q7-3 .styles_row-wrapper__3CX8c {
  display: flex;
}

.styles_container__3A2KK {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.styles_container__3A2KK .styles_header__h00RH {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  color: #6D6F72;
}

.styles_container__3A2KK .styles_header__h00RH .styles_main__2k4xq {
  font-size: 4em;
}

.styles_container__3A2KK .styles_header__h00RH .styles_sub__2tE8W {
  font-size: 1em;
}
.home-container {
  display: flex;
  flex-direction: column;
  margin: 15px;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}



.q-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 33%;
}

.q-box .title {
  white-space: nowrap;
}

.q-box .q-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.q-box .q-content > * {
  box-sizing: border-box;
  margin: 10px;
  flex-shrink: 1;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.14);
  padding: 3px;
  flex-basis: 20%;
}

.styles_container__1EpIF {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.styles_address-section__10Tw- {
  width: auto;
  min-height: 32px;
  margin: 22px 0;
  padding: 0 19px;
  border-bottom: 1px solid #979797;
  text-align: center;

  color: rgba(151, 151, 151, 0.52);
  font-size: 24px;
  line-height: 33px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
}

.container section.estimation-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 300px;
  border: 1px solid #707070;
  color: #2699fb;
  padding: 10px;
  padding-bottom: 35px;
}

.container section.estimation-box .header-text {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.container section.estimation-box .estimated-value {
  font-size: 30px;
  font-weight: bold;
}

.container section.address-section {
  width: 100%;
  max-width: 700px;
  margin: 22px 0;
  border-bottom: 1px solid #707070;
  border-top: 1px solid;
  text-align: center;
  font-size: 20px;
  color: #707070;
}

.container section.quotes {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 700px;
}

.styles_card-list__1aRsc {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.styles_card-list__1aRsc > * {
  margin: 14px;
}

.styles_helper-text__29rZL {
  color: #8F99AC;
  font-size: 20px;
  line-height: 27px;
  margin-left: 52px;
  margin-bottom: 43px;
}

.styles_card-list__25Hne {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.styles_card-list__25Hne > * {
  margin: 14px;
}

.styles_buttons-wrapper__3Y1RP {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.styles_buttons-wrapper__3Y1RP button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  min-height: 48px;
  margin: 0 10px;
  margin-top: 20px;
}

.styles_slider-wrapper__21Bar {
  display: flex;
  color:#009EFB;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 20px;
}

.styles_content__2uxUN {
  width: 80%;
  color:#009EFB;
  font-size: 1em;
}

.styles_sliderContainer__29ydJ {
  width: 100%;
}

.styles_tag__ePbDN {
  width: 30%;
  font-size: 1.5em;
  margin-bottom: 45px;
}
.styles_follower__-DfX9 {
  text-align: end;
  font-size: 1.5em;
}

.styles_title__2Pjo0 {
  margin-bottom: 50px;
}

.styles_buttons-wrapper__3Y1RP {
  margin: 77px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap-reverse;
  max-width: 100vw;
}

.styles_buttons-wrapper__3Y1RP button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  max-width: 296px;
  min-height: 48px;
  margin: 0 10px;

  border: none;
  border-radius: 0;

  color: #ffffff;
  font-size: 25px;
  line-height: 34px;

  height: 80px;
  width: 322px;
  background-color: #009efb;

  flex: 1 1;
}

.styles_buttons-wrapper__3Y1RP button.styles_clear__3qmrP {
  color: #009efb;
  background-color: transparent;
  border: 1px solid;
}

.styles_buttons-wrapper__3Y1RP button.styles_clear__3qmrP:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: white;
}

.styles_content__Id6ke {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.styles_helper-text__33llx {
  color: #009efb;
  font-size: 20px;
  line-height: 27px;
  margin-top: 87px;
  margin-bottom: 31px;
}

.styles_card-list__3yump {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.styles_card-list__3yump > * {
  margin: 14px;
}

.styles_buttons-wrapper__1CJ6k {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.styles_buttons-wrapper__1CJ6k button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  min-height: 48px;
  margin: 0 10px;
}

.styles_check-list__2mYq5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.styles_container__1Hicl {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.styles_comment__21Nk5 {
  color: #009efb;
  font-size: 42px;
  font-weight: 600;
  line-height: 57px;
  margin-top: 77px;
}

.styles_content__2iGU_ {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.styles_content__2iGU_ .styles_contact-form__1p1Ky {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 51px;
  min-height: 130px;
}

.styles_terms__2QHjE {
  color: #979797;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 200px;
}

.styles_terms__2QHjE a {
  font-weight: 900;
  text-decoration: underline;
}


.styles_buttons-wrapper__2yCnN {
  margin: 77px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap-reverse;
  max-width: 100vw;
}

.styles_title__32hXc {
  margin-bottom: 50px;
  color:#009EFB;
  font-size: 1em;
}

.styles_buttons-wrapper__2yCnN button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  max-width: 296px;
  min-height: 48px;
  margin: 0 10px;

  border: none;
  border-radius: 0;

  color: #ffffff;
  font-size: 25px;
  line-height: 34px;

  height: 80px;
  width: 322px;
  background-color: #009efb;

  flex: 1 1;
}

.styles_buttons-wrapper__2yCnN button.styles_clear__29lVp {
  color: #009efb;
  background-color: transparent;
  border: 1px solid;
}

.styles_buttons-wrapper__2yCnN button.styles_clear__29lVp:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: white;
}

.styles_buttons-wrapper__s7ZJn {
  margin: 77px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap-reverse;
  max-width: 100vw;
}

.styles_buttons-wrapper__s7ZJn button {
  font-size: 12px;
  font-weight: bold;
  min-width: 148px;
  max-width: 296px;
  min-height: 48px;
  margin: 0 10px;

  border: none;
  border-radius: 0;

  color: #ffffff;
  font-size: 25px;
  line-height: 34px;

  height: 80px;
  width: 322px;
  background-color: #009efb;

  flex: 1 1;
}

.styles_buttons-wrapper__s7ZJn button.styles_clear__14RH- {
  color: #009efb;
  background-color: transparent;
  border: 1px solid;
}

.styles_buttons-wrapper__s7ZJn button.styles_clear__14RH-:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: white;
}

.styles_content__ORXAM {
  width: 100%
}

.styles_summary-content__3ZTpB {
  display: flex;
  padding: 0px 40px;
}

.styles_holder__31t1V {
  width: 100%;
  margin: 0px 10px;
}

.styles_title__3wC0y {
  font-size: 1.5em;
  color: #8e8e8e;
  justify-content: center;
  display: flex;
}
.layout {
  display: flex;
  box-sizing: border-box;
  height: auto;
  min-height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center; }
  .layout .layout-content {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0 30px;
    margin: 0 auto; }

.styles_container__1CHc- {
  display: block;
}

.styles_address-section__1Cv3B {
  width: 100%;
  min-height: 32px;
  margin: 22px 0;
  border-bottom: 1px solid #707070;
  border-top: 1px solid;
  text-align: center;
  font-size: 20px;
  color: #707070;
}

.styles_content-wrapper__19e9f {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
