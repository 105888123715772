.container {
  padding: 5px 15px;
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 3px 4px 0 rgba(40,47,60,0.05), 0 20px 25px 0 rgba(40,47,60,0.05), 0 0 25px 0 rgba(40,47,60,0.05);
}

.item {
  color: #8e8e8e;
  display: flex;
  font-size: 1em;
  align-items: center;
  padding-right: 15px;
}

.item-dot {
  color: #FD7F3F;
  font-size: 1.5em;
  padding: 0px 4px;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.pagination-item {
  color: #FD7F3F;
  font-size: 1.5em;
  font-weight: 200;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  border-width: 0px;
  padding: 0px;
  background-color: #fff;
}

.page {
  color: #FD7F3F;
  font-size: 0.5em;
  opacity: 0.5;
  margin: 0px 5px;
  border-width: 0px;
  padding: 0px;
  background-color: #f6fcff;
}

.current-page {
  opacity: 1;
  font-size: 0.7em;
}

.footer {
  widows: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-empty {
  color: #25afff;
  margin: 1px;
  height: 3px;
}

.pages {
  display: flex;
  justify-content: space-around;
}