.property-card {
  display: flex;
  flex-direction: column;
  width: 212px;
  height: 280px;
  color: #6D6B6D;
  box-shadow: 0 3px 4px 0 rgba(40,47,60,0.05), 0 20px 25px 0 rgba(40,47,60,0.05), 0 0 25px 0 rgba(40,47,60,0.05);
}

.property-holder {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  color: #009efb;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}

.property-title {
  color: #5A6169;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}

.property-description {
  color: #8e8e8e;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.property-more {
  color: #5A6169;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;
}

.button-holder {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8f99ac36;
  opacity: 0.5;
  color: #8F99AC;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
}
.property-card.selected .button-holder {
  background-color:#3FAFFD;
  color: #fff;
  opacity: 1;
}

.button-holder:hover {
  cursor: pointer;
}
