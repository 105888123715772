.nav-map-container {
  position: sticky;
  top: 56px;
  left: 0px;
  background-color: #fff;
}

.nav-map-container .bar {
  display: inline-block;
  background-color: #FD7F3F;
  height: 12px;
}
.nav-map-container .barBG {
  display: inline-block;
  background-color: #E9EAEE;
  height: 12px;
}

.nav-map-container .steps-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-top: 2px;
}

.nav-map-container .steps-container > .step {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.4;
  color: #6D6B6D;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;

  white-space: nowrap;
}

.nav-map-container .steps-container > .step.highlight {
  opacity: 1;
}

.nav-map-container .steps-container > .step.hidden {
  opacity: 0;
}
