.container {
  width: 100%;
  height: 0;
  padding-bottom: 15%;
  position: relative;
}
.player {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}