.container {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 20px;
}

.content-box {
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0 28px;
  padding: 20px;
}

.content-text {
  color: #FD7F3F;
  font-size: 30px;
  font-weight: bold;
}

.title-text {
  text-align: center;
  color: #FD7F3F;
  font-size: 32px;
  line-height: 43px;
  /* white-space: pre; */
  margin-right: 6px;
}

.value-text {
  color: #FD7F3F;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
}
