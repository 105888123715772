.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-list > * {
  margin: 14px;
}

.helper-text {
  color: #8F99AC;
  font-size: 20px;
  line-height: 27px;
  margin-left: 52px;
  margin-bottom: 43px;
}
