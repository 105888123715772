.search-wrapper {
  width: 75%;
}

.search-wrapper .sub-header {
  width: 100%;
  color: #6D6F72;
  font-size: 2em;
}

.search-wrapper .row-wrapper {
  display: flex;
}
